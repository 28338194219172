<template>
    <div>
        <!-- Table Container Card -->
        <b-card class="mb-0">
            
                <b-row>
                    <b-col cols="12" class="mt-1">
                        <InvoiceList />
                    </b-col>
                </b-row>
        </b-card>
    </div>
</template>
  
<script>
import InvoiceList from './TableInvoiceList.vue'
import {
    BCard, BRow, BCol
} from 'bootstrap-vue'



export default {
    components: {
        BCard,
        BRow,
        BCol,
        InvoiceList

    }
}
</script>
